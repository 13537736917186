<template>
    <div>
        <LoaderComponent v-if="loading"/>
        <div class="mb-1 d-flex align-items-center text-info justify-content-between">
            <div @click="$router.go(-1)" class="d-flex cursor-pointer">
                <feather-icon
                    size="20"
                    class="mr-1"
                    icon="ArrowLeftIcon"
                />
                <h6 class="m-0 text-info">Назад</h6>
            </div>

            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-info"
                @click="duplicateOrder"
                size="sm"
                :disabled="loadingDuplicateOrder"
            >
                <b-spinner v-if="loadingDuplicateOrder" small></b-spinner>
                <feather-icon
                    icon="CopyIcon"
                    style="margin-right: 5px"
                />
                Дублировать заказ
            </b-button>
        </div>

        <b-card v-if="order" class="mb-2 pr-0">
            <div class="d-flex justify-content-between dotted-gradient mb-1">
                <h5 class="mr-1">{{ translate.translate('key', 'klient') }}:</h5>
                <h5 class="text-info">
                    <span>{{ order.contractor.name }}</span>
                </h5>
            </div>
            <div class="d-flex justify-content-between dotted-gradient mb-1">
                <h5>Инн:</h5>
                <h5><span>{{ order.contractor.inn ? order.contractor.inn : 'не указано' }}</span></h5>
            </div>
            <div class="d-flex justify-content-between dotted-gradient mb-1">
                <h5>{{ translate.translate('key', 'nomerZakaza') }}</h5>
                <h5><span>{{ order.order.number }}</span></h5>
            </div>
            <div class="d-flex justify-content-between dotted-gradient mb-1">
                <h5>Дата создания:</h5>
                <h5>{{ order.created_at | datetime }}</h5>
            </div>
            <div class="d-flex justify-content-between dotted-gradient mb-1">
                <h5>{{ translate.translate('key', 'dataZakaza') }}:</h5>
                <h5>{{ order.order.date | date }}</h5>
            </div>
            <div class="d-flex justify-content-between dotted-gradient mb-1">
                <h5>Статус:</h5>
                <h5>
                    <b-badge pill :variant="'light-'+getOrderStatusVariant(order.status)">
                        {{ translate.translate('key', getOrderStatusText(order.status)) }}
                    </b-badge>
                </h5>
            </div>
            <div class="d-flex justify-content-between">
                <h5>{{ translate.translate('key', 'obshayaSumma') }}:</h5>
                <h5><span class="text-warning">{{ totalAmount | decimal }}</span> сум</h5>
            </div>
        </b-card>

        <div v-if="order">
            <h4>Товары: {{ order.items.length }} шт</h4>
            <b-card v-for="(item, index) in order.items" :key="index">
                <div class="d-flex">
                    <div class="mr-2 w-25">
                        <b-img
                            v-if="getImageUrl(item)"
                            alt="image"
                            thumbnail
                            fluid
                            style="width: 50px; height: 50px"
                            class="card-img-top"
                            :src="getImageUrl(item)"
                        />
                        <b-img
                            v-else
                            alt="image"
                            style="width: 50px; height: 50px"
                            fluid
                            class="card-img-top"
                            src="@/assets/images/no-photo.svg"
                        />
                    </div>
                    <div class="w-75">
                        <b-link :to="'/app/product/details/'+item.id">
                            <h5 class="text-info">{{ item.name }}</h5>
                        </b-link>
                        <div class="h5 d-flex justify-content-between mt-1">
                            <h6>{{ translate.translate('key', 'kolichestvo') }}:</h6>
                            <h6><span>{{ item.quantity | decimal }}</span> шт</h6>
                        </div>
                        <div class="h5 d-flex justify-content-between dotted-gradient">
                            <h6>{{ translate.translate('key', 'sena') }}:</h6>
                            <h6><span>{{ item.price_amount | decimal }}</span> сум</h6>
                        </div>

                        <div class="h5 mt-1 d-flex justify-content-between">
                            <h6>{{ translate.translate('key', 'summa') }}:</h6>
                            <h6><span class="text-warning">{{ (item.quantity * item.price_amount) | decimal }}</span>
                                сум</h6>
                        </div>
                    </div>
                </div>
            </b-card>
        </div>
    </div>
</template>

<script>
import OrderService from '../../../../services/order.service'
import translate from '@/translation/translate'
import LoaderComponent from '@/components/LoaderComponent'
import { getOrderStatusText, getOrderStatusVariant } from '@/constants/order-status'
import { mapActions, mapGetters } from 'vuex'
import { ApiHippoUrl } from '@/constants/settings'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
    name: 'UserOrderView',
    components: { LoaderComponent },
    directives: {
        Ripple,
    },
    data() {
        return {
            ApiHippoUrl,
            order: null,
            translate,
            loading: false,
            loadingDuplicateOrder: false,
        }
    },
    computed: {
        ...mapGetters({
            products: 'getProducts',
            orderStore: 'getOrder',
        }),
        fields() {
            return [
                '#',
                {
                    key: 'name',
                    label: translate.translate('key', 'naimenovaniya')
                },
                {
                    key: 'quantity',
                    label: translate.translate('key', 'kolichestvo'),
                    class: 'text-center'
                },
                {
                    key: 'price',
                    label: translate.translate('key', 'sena'),
                    class: ['min-width-120', 'text-right']
                },
                {
                    key: 'total',
                    label: translate.translate('key', 'summa'),
                    class: ['min-width-120', 'text-right']
                }
            ]
        },
        totalAmount() {
            return this.order.items.reduce((total, order_item) => {
                return total + (order_item.quantity * order_item.price_amount)
            }, 0)
        },
    },
    methods: {
        ...mapActions(['registerOrder']),
        duplicateOrder() {
            if (this.order.items.length === 0) {
                return this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Mahsulot kiritlmagan',
                        icon: 'AlertTriangleIcon',
                        variant: 'warning',
                    },
                })
            }
            if (this.orderStore && this.orderStore.id) {
                return this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Sizda buyurtma yaratilgan.',
                        icon: 'AlertTriangleIcon',
                        variant: 'warning',
                    },
                })
            }

            this.$bvModal.msgBoxConfirm('Buyurtmadan takror yaratmoqchimisiz?', {
                okTitle: 'Xa',
                cancelTitle: 'Yo\'q',
                hideHeaderClose: false,
                buttonSize: 'sm',
                centered: false
            })
            .then(value => {
                if (value) {
                    let item_not_found_count = 0
                    let order_items = []
                    this.order.items.forEach(item => {
                        let foundProduct = this.products.find(product => {
                            return product.item.id === item.id
                        })
                        if (foundProduct) {
                            let payload_item = {
                                global_item_id: foundProduct.item.id,
                                global_currency_id: foundProduct.price.common_price.global_currency_id,
                                price_amount: foundProduct.price.common_price.amount,
                                quantity: item.quantity,
                            }
                            order_items.push(payload_item)
                        } else {
                            item_not_found_count++
                        }
                    })

                    this.loadingDuplicateOrder = true
                    this.registerOrder({
                        contractor_id: this.order.contractor.id,
                        order: {
                            number: String(new Date().getTime())
                            .slice(3),
                            date: this.$options.filters.api_date_format(new Date())
                        },
                        contract: null,
                        expires_on: null,
                        items: order_items
                    })
                    .then(res => {
                        if (item_not_found_count > 0) {
                            this.$bvToast.toast('Topilmagan tovarlar soni: ' + item_not_found_count, {
                                title: 'Ogohlantrish',
                                autoHideDelay: 15000,
                                variant: 'warning',
                                bodyClass: 'h4 text-white'
                            })
                        } else {
                            this.successMessage()
                        }
                    })
                    .catch(err => {
                        this.errorMessage()
                    })
                    .finally(() => {
                        this.loadingDuplicateOrder = false
                    })
                }
            })
        },
        getImageUrl(item) {
            if (this.products.length === 0) {
                return ''
            }

            let foundProduct = this.products.find(product => {
                return product.item.id === item.id
            })
            if (foundProduct && foundProduct.item.images.length > 0) {
                return ApiHippoUrl + `/cdn/${foundProduct.item.images[0].path}`
            } else {
                return ''
            }
        },
        getOrderStatusText,
        getOrderStatusVariant,
        successMessage() {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Успешно',
                    icon: 'CheckIcon',
                    variant: 'success',
                },
            })
        },
        errorMessage() {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Возникла ошибка',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                },
            })
        },
    },
    created() {
        if (this.$route.params.id) {
            this.loading = true
            OrderService.getOrder(this.$route.params.id)
            .then(res => {
                if (res && res.data) {
                    this.order = res.data
                }
            })
            .catch(err => {
            })
            .finally(() => {
                this.loading = false
            })
        }
    }
}
</script>

<style lang="scss">
</style>
<style lang="scss" scoped>
.checkout-form-wizard ::v-deep {
    .wizard-tab-content {
        box-shadow: none !important;
        background: transparent !important;
        padding: 0;
    }
}
</style>
