export const OrderCanceledLabel = "orderStatusCanceled"
export const OrderDeclinedLabel = "orderStatusDeclined"
export const OrderNewLabel = "orderStatusNew"
export const OrderAcceptedLabel = "orderStatusAccepted"
export const OrderProgressLabel = "orderStatusProgress"
export const OrderCompletedLabel = "orderStatusCompleted"

export const OrderCanceled = -2
export const OrderDeclined = -1
export const OrderNew = 0
export const OrderAccepted = 1
export const OrderProgress = 2
export const OrderCompleted = 3

export const OrderCanceledVariant = 'danger'
export const OrderDeclinedVariant = 'danger'
export const OrderNewVariant = 'info'
export const OrderAcceptedVariant = 'primary'
export const OrderProgressVariant = 'warning'
export const OrderCompletedVariant = 'success'

export const getOrderStatusText = status => {
    switch (status) {
        case OrderCanceled:
            return OrderCanceledLabel
        case OrderDeclined:
            return OrderDeclinedLabel
        case OrderNew:
            return OrderNewLabel
        case OrderAccepted:
            return OrderAcceptedLabel
        case OrderProgress:
            return OrderProgressLabel
        case OrderCompleted:
            return OrderCompletedLabel
        default:
            return OrderNewLabel
    }
}

export const getOrderStatusVariant = status => {
    switch (status) {
        case OrderCanceled:
            return OrderCanceledVariant
        case OrderDeclined:
            return OrderDeclinedVariant
        case OrderNew:
            return OrderNewVariant
        case OrderAccepted:
            return OrderAcceptedVariant
        case OrderProgress:
            return OrderProgressVariant
        case OrderCompleted:
            return OrderCompletedVariant
        default:
            return OrderNewVariant
    }
}

export const OrderStatuses = [
    {
        label: OrderAcceptedLabel,
        value: OrderAccepted,
        variant: OrderAcceptedVariant
    },
    {
        label: OrderProgressLabel,
        value: OrderProgress,
        variant: OrderProgressVariant
    },
    {
        label: OrderCompletedLabel,
        value: OrderCompleted,
        variant: OrderCompletedVariant
    },
    {
        label: OrderDeclinedLabel,
        value: OrderDeclined,
        variant: OrderDeclinedVariant
    },
    {
        label: OrderNewLabel,
        value: OrderNew,
        variant: OrderNewVariant
    },
]

export const FilterOrderStatuses = [
    {
        label: 'Все заказы',
        value: null,
        variant: OrderAcceptedVariant
    },
    {
        label: 'Новые заказы',
        value: OrderNew,
        variant: OrderNewVariant
    },
    {
        label: 'Принятые заказы',
        value: OrderAccepted,
        variant: OrderAcceptedVariant
    },
    {
        label: 'Заказы в процессе',
        value: OrderProgress,
        variant: OrderProgressVariant
    },
    {
        label: 'Завершённые заказы',
        value: OrderCompleted,
        variant: OrderCompletedVariant
    },
    {
        label: 'Отклонённые заказы',
        value: OrderDeclined,
        variant: OrderDeclinedVariant
    },
]
